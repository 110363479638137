export const locations = [
  {
    id: 1,
    title: 'Steelpointe Harbor',
    street: '255 E Main St',
    city: 'Bridgeport',
    state: 'CT',
    zip: '06608',
    county: 'Fairfield County',
    county_population: 916829,
    service_population: 522000,
    drive_thru: false,
    location_type: 'urban',
    open_time: 7,
    close_time: 21,
  },
  {
    id: 2,
    title: 'River Valley',
    street: '965 White Plains Rd',
    city: 'Trumbull',
    state: 'CT',
    zip: '06611',
    county: 'Fairfield County',
    county_population: 916829,
    service_population: 189000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 3,
    title: 'Ridgeway Center',
    street: '2139 Summer St',
    city: 'Stamford',
    state: 'CT',
    zip: '06905',
    county: 'Fairfield County',
    county_population: 916829,
    service_population: 312000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 4,
    title: 'North Street Shopping Center',
    street: '109 North St',
    city: 'Danbury',
    state: 'CT',
    zip: '06811',
    county: 'Fairfield County',
    county_population: 916829,
    service_population: 245000,
    drive_thru: true,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 5,
    title: 'Downtown Hartford',
    street: '185 Asylum St',
    city: 'Hartford',
    state: 'CT',
    zip: '06103',
    county: 'Hartford County',
    county_population: 894014,
    service_population: 673000,
    drive_thru: false,
    location_type: 'urban',
    open_time: 6,
    close_time: 22,
  },
  {
    id: 6,
    title: 'Colt Park',
    street: '140 Huyshope Ave',
    city: 'Hartford',
    state: 'CT',
    zip: '06106',
    county: 'Hartford County',
    county_population: 894014,
    service_population: 519000,
    drive_thru: true,
    location_type: 'urban',
    open_time: 7,
    close_time: 20,
  },
  {
    id: 7,
    title: 'Bristol Plaza',
    street: '641 Farmington Ave',
    city: 'Bristol',
    state: 'CT',
    zip: '06010',
    county: 'Hartford County',
    county_population: 894014,
    service_population: 226000,
    drive_thru: true,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 8,
    title: 'Epicure Shopping Center',
    street: '838 Farmington Ave',
    city: 'Farmington',
    state: 'CT',
    zip: '06032',
    county: 'Hartford County',
    county_population: 894014,
    service_population: 176000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 9,
    title: 'Simsbury Town Shops',
    street: '930 Hopmeadow Rd',
    city: 'Simsbury',
    state: 'CT',
    zip: '06070',
    county: 'Hartford County',
    county_population: 894014,
    service_population: 184000,
    drive_thru: false,
    location_type: 'rural',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 10,
    title: 'Grover Park',
    street: '166 Washington Ave',
    city: 'North Haven',
    state: 'CT',
    zip: '06473',
    county: 'New Haven County',
    county_population: 862477,
    service_population: 336000,
    drive_thru: true,
    location_type: 'suburban',
    open_time: 6,
    close_time: 20,
  },
  {
    id: 11,
    title: 'Yale University',
    street: '1070 Chapel St',
    city: 'New Haven',
    state: 'CT',
    zip: '06510',
    county: 'New Haven County',
    county_population: 862477,
    service_population: 340000,
    drive_thru: false,
    location_type: 'urban',
    open_time: 7,
    close_time: 22,
  },
  {
    id: 12,
    title: 'Brass Mill Center',
    street: '255 Union St',
    city: 'Waterbury',
    state: 'CT',
    zip: '06706',
    county: 'New Haven County',
    county_population: 862477,
    service_population: 273000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 13,
    title: 'Gulfside',
    street: '975 Boston Post Rd',
    city: 'Milford',
    state: 'CT',
    zip: '06460',
    county: 'New Haven County',
    county_population: 862477,
    service_population: 462000,
    drive_thru: false,
    location_type: 'urban',
    open_time: 6,
    close_time: 19,
  },
  {
    id: 14,
    title: 'Central New London',
    street: '13 Washington St',
    city: 'New London',
    state: 'CT',
    zip: '06320',
    county: 'New London County',
    county_population: 274055,
    service_population: 188000,
    drive_thru: false,
    location_type: 'urban',
    open_time: 6,
    close_time: 21,
  },
  {
    id: 15,
    title: 'Mohegan Shops',
    street: 'One Mohegan Sun Blvd',
    city: 'Montville',
    state: 'CT',
    zip: '06382',
    county: 'New London County',
    county_population: 274055,
    service_population: 138000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 16,
    title: 'Old Town Linwood',
    street: '95 Linwood Ave',
    city: 'Colchester',
    state: 'CT',
    zip: '06415',
    county: 'New London County',
    county_population: 274055,
    service_population: 97000,
    drive_thru: true,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 17,
    title: 'Market32',
    street: '990 Torringford St',
    city: 'Torrington',
    state: 'CT',
    zip: '06790',
    county: 'Litchfield County',
    county_population: 189927,
    service_population: 110000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 6,
    close_time: 19,
  },
  {
    id: 18,
    title: 'Riverside View',
    street: '38 Danbury Rd',
    city: 'New Milford',
    state: 'CT',
    zip: '06776',
    county: 'Litchfield County',
    county_population: 189927,
    service_population: 58000,
    drive_thru: false,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 19,
    title: 'Middletown Plaza',
    street: '871 Washington St',
    city: 'Middletown',
    state: 'CT',
    zip: '06457',
    county: 'Middlesex County',
    county_population: 165676,
    service_population: 104000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 8,
    close_time: 20,
  },
  {
    id: 20,
    title: 'Waterfront',
    street: '15 Main St',
    city: 'Old Saybrook',
    state: 'CT',
    zip: '06475',
    county: 'Middlesex County',
    county_population: 165676,
    service_population: 65000,
    drive_thru: true,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 21,
    title: 'University of Connecticut',
    street: '2075 Hillside Rd',
    city: 'Mansfield',
    state: 'CT',
    zip: '06269',
    county: 'Tolland County',
    county_population: 152691,
    service_population: 87000,
    drive_thru: false,
    location_type: 'suburban',
    open_time: 7,
    close_time: 22,
  },
  {
    id: 22,
    title: 'Vernon Village',
    street: '135 Talcottville Rd',
    city: 'Vernon',
    state: 'CT',
    zip: '06066',
    county: 'Tolland County',
    county_population: 152691,
    service_population: 42000,
    drive_thru: true,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 23,
    title: 'Rotary Park',
    street: '319 Kennedy Dr',
    city: 'Putnam',
    state: 'CT',
    zip: '06260',
    county: 'Windham County',
    county_population: 118428,
    service_population: 26000,
    drive_thru: true,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
  {
    id: 24,
    title: 'Owen Bell Park',
    street: '541 Hartford Pike',
    city: 'Dayville',
    state: 'CT',
    zip: '06241',
    county: 'Windham County',
    county_population: 118428,
    service_population: 35000,
    drive_thru: true,
    location_type: 'rural',
    open_time: 8,
    close_time: 19,
  },
];
